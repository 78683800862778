type FormMessageProps = {
  message?: any;
  className?: string;
  props?: React.HTMLAttributes<HTMLParagraphElement>;
};

const FormMessage: React.FC<FormMessageProps> = ({
  message,
  className,
  props,
}) => {
  return (
    <p
      className={` ${className} text-sm pr-3 font-medium text-right text-red-500 dark:text-red-900`}
      {...props}
    >
      * {message}
    </p>
  );
};

export default FormMessage;
