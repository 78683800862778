import RequireAuth from "@auth-kit/react-router/RequireAuth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { lazy, Suspense, useEffect, useState } from "react";
import AuthProvider from "react-auth-kit";
import createStore from "react-auth-kit/createStore";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/common/Loader";
import { SidebarProvider } from "./components/sidebar/SidebarContext";
import "./index.css";
import Login from "./pages/Login";
import routes from "./routes";

const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const store = createStore({
    authName: "_wahaj_auth",
    authType: "cookie",
    cookieDomain: window.location.hostname,
    cookieSecure: false,
  });

  return loading ? (
    <Loader />
  ) : (
    <>
      <AuthProvider store={store}>
        <QueryClientProvider client={queryClient}>
        <SidebarProvider>
          <Routes>
            <Route element={<DefaultLayout />}>
              {routes?.map((routes, index) => {
                const { path, component: Component } = routes;
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <Suspense fallback={<Loader />}>
                        <RequireAuth fallbackPath={"/login"}>
                          
                            <Component />
                         
                        </RequireAuth>
                      </Suspense>
                    }
                  />
                );
              })}
            </Route>
            <Route path="/login" element={<Login />} />
          </Routes>
          </SidebarProvider>
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
