import * as yup from "yup";

export const loginSchema = yup.object().shape({
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "يجب أن يحتوي رقم الهاتف على أرقام فقط")
    .min(10, "يجب أن يتكون رقم الهاتف من 10 أرقام على الأقل")
    .max(15, "يجب أن يتكون رقم الهاتف من 15 رقمًا كحد أقصى")
    .required("الرجاء إدخال رقم هاتف صالح"),
  password: yup.string().required("كلمة المرور مطلوبة"),
});

export const topicSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال الاسم بالعربي"),
  name_en: yup.string().required("يرجى إدخال الاسم بالإنجليزية"),
  sequence: yup.number()
  .required("يرجى إدخال الحقل المطلوب")
  .lessThan(255, "يجب أن يكون الرقم أقل من 255")
  .typeError("يجب أن يكون القيمة رقمًا"),
  chapter_id: yup.string().required("يرجى ادخال الحقل المطلوب"),
});

export const subjectSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال اسم المادة بالعربي"),
  name_en: yup.string().required("يرجى إدخال اسم المادة بالإنجليزية"),
  stage_id: yup.string().required("يرجى اختيار المرحلة"),
  description_ar: yup.string().required("يرجى إدخال الوصف بالعربي"),
  description_en: yup.string().required("يرجى إدخال الوصف بالإنجليزية"),
  sequence: yup.number()
  .required("يرجى إدخال الحقل المطلوب")
  .lessThan(255, "يجب أن يكون الرقم أقل من 255")
  .typeError("يجب أن يكون القيمة رقمًا"),
  icon: yup
    .mixed<FileList>()
    .required("ملف الصورة المصغرة مطلوب")
    .test("fileType", "يجب وضع صورة", (value) => {
      if (value && value[0]) {
        return value[0].type.startsWith("image/");
      }
      return false;
    })
    .test("fileSize", "حجم الصورة اكبر من الحجم المسموح", (file) => {
      if (file && file[0]) {
        return file[0].size <= 2000000;
      }
      return false;
    }),
});

export const editSubjectSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال اسم المادة بالعربي"),
  name_en: yup.string().required("يرجى إدخال اسم المادة بالإنجليزية"),
  stage_id: yup.string().required("يرجى اختيار المرحلة"),
  description_ar: yup.string().required("يرجى إدخال الوصف بالعربي"),
  description_en: yup.string().required("يرجى إدخال الوصف بالإنجليزية"),
  sequence: yup.number()
  .required("يرجى إدخال الحقل المطلوب")
  .lessThan(255, "يجب أن يكون الرقم أقل من 255")
  .typeError("يجب أن يكون القيمة رقمًا")
});

export const chapterSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال اسم الفصل بالعربي"),
  name_en: yup.string().required("يرجى إدخال اسم الفصل بالإنجليزية"),
  subject_id: yup.string().required("يرجى اختيار المادة"),
  sequence: yup.number()
  .required("يرجى إدخال الحقل المطلوب")
  .lessThan(255, "يجب أن يكون الرقم أقل من 255")
  .typeError("يجب أن يكون القيمة رقمًا")
});

export const courseSchema = yup.object().shape({
  title: yup.string().required("يرجى ادخال اسم الكورس بالانكليزية"),
  teacher_id: yup.string().required("يرجى اخال الحقل المطلوب "),
  subject_id: yup.string().required("يرجى اخال الحقل المطلوب "),
  description: yup.string().required("يرجى اخال الحقل المطلوب"),
});

export const examSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال اسم الامتحان  بالعربي"),
  name_en: yup.string().required("يرجى إدخال اسم الامتحان  بالإنجليزية"),
  related_id: yup.string().when("exam_type", {
    is: (value: string) => value !== "App\\Models\\Subject",
    then: (schema) =>
      schema.required("يرجى اختيار نوع الربط") as yup.StringSchema,
    otherwise: (schema) => schema.nullable() as yup.StringSchema,
  }),
  exam_type_id: yup.string().required("يرجى اختيار نوع الامتحان"),
  subject_id: yup.string().required("يرجى اختيار المادة"),
});

export const editExamSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال اسم الامتحان بالعربي"),
  name_en: yup.string().required("يرجى إدخال اسم الامتحان بالإنجليزية"),
  related_id: yup.string().required("يرجى اختيار نوع الربط"),
  related_type: yup.string().required("يرجى اختيار نوع الربط"),
  exam_type_id: yup.string().required("يرجى اختيار نوع الامتحان"),
});

export const governorateSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال الاسم  بالعربي"),
  name_en: yup.string().required("يرجى إدخال الاسم بالانكليزي"),
});

export const directorateSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال الاسم  بالعربي"),
  name_en: yup.string().required("يرجى إدخال الاسم بالانكليزي"),
  governorate_id: yup.string().required("يرجى اختيار اسم المحافظة"),
});

export const stageSchema = yup.object().shape({
  name_ar: yup.string().required("يرجى إدخال الاسم  بالعربي"),
  name_en: yup.string().required("يرجى إدخال الاسم بالانكليزي"),
  sequence: yup.number()
    .required("يرجى إدخال الحقل المطلوب")
    .lessThan(255, "يجب أن يكون الرقم أقل من 255")
    .typeError("يجب أن يكون القيمة رقمًا")
});

export const questionSchema = yup.object().shape({
  exam_id: yup.number().required("رقم الامتحان مطلوب"),
  question: yup.string().required("السؤال مطلوب"),
  type: yup.string().required("نوع السؤال مطلوب"),
  score: yup.number()
    .typeError("يجب أن يكون الدرجة رقمًا")
    .required("الدرجة مطلوبة"),
  duration: yup.string().required("المدة مطلوبة"),
  answer: yup.string().required("الجواب مطلوب"),

  id: yup.number().required("رقم الامتحان مطلوب"),
 
 
  // choices: Yup.array()
  //   .of(
  //     Yup.object().shape({
  //       choice: Yup.string().required("الاختيار مطلوب"),
  //       is_true: Yup.boolean().required(
  //         "يرجى تحديد ما إذا كان الاختيار صحيحًا أم لا"
  //       ),
  //     })
  //   )
  //   .test(
  //     "atLeastOneTrue",
  //     "يجب اختيار خيار واحد على الأقل وأن يكون صحيحًا",
  //     function (value): value is Choice[] {
  //       return !!value && value.some((choice) => choice.is_true);
  //     }
  //   )
  //   .test(
  //     "atLeastOneChoice",
  //     "يجب اختيار خيار واحد على الأقل",
  //     function (value): value is Choice[] {
  //       return !!value && value.length > 0;
  //     }
  //   ),
});
