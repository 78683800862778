import React, { createContext, useContext, useState, useEffect } from "react";

interface SidebarContextProps {
  expanded: boolean;
  toggleExpanded: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [expanded, setExpanded] = useState<boolean>(() => {
    const savedState = localStorage.getItem("sidebar-expanded");
    return savedState === null ? true : savedState === "true";
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", expanded.toString());
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <SidebarContext.Provider value={{ expanded, toggleExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
