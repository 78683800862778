import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Logo4 from "../../images/logo/logo4.png";
import { useAuthLogin } from "../../lib/Auth/auth-login";
import convertToJWT from "../../lib/Auth/convertToJWT";
import { loginSchema } from "../../lib/yup/schemas";
import FormMessage from "../common/FormMessage";
import { SmallLoading } from "../common/Loader/SmallLoading";
interface LoginFormInputs {
  phone_number: string;
  password: string;
}
const LoginForm = () => {
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginSchema),
  });
  let { mutate: login, isLoading: isSigningIn } = useAuthLogin({
    async onSuccess(data: any) {
      // FIXME: Temporary configuration: Convert the received token to JWT format until the backend sends JWT tokens directly.
      const jwtToken = await convertToJWT(data.auth_token);

      if (
        signIn({
          auth: {
            token: jwtToken,
          },
          userState: {
            name: data.name,
            email: data.email,
            id: data.id,
            phone: data.phone_number,
            role: data.role,
            phone_number: data.phone_number,
            image: data.image,
            auth_token: data.auth_token,
          },
        })
      ) {
        navigate("/governorates");
      } else {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
      }
    },
    onError(err: any) {
      if (err.response && err.response.status === 401) {
        toast.error("لم يتم العثور على المستخدم");
      } else {
        toast.error("حدث خطأ أثناء تسجيل الدخول");
      }
    },
  });

  const onSubmit = (values: LoginFormInputs) => {
    if (!isSigningIn) {
      login(values);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/governorates");
    }
  }, []);

  return (
    <>
      <ToastContainer position="top-right" rtl={true} />

      <div className="flex h-[calc(100vh-2rem)] items-center justify-center">
        <div className="w-full max-w-md rounded-xl border border-stroke bg-white p-6 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div className="flex items-center justify-center rounded-xl border-stroke bg-tealPrimary p-6 dark:border-strokedark">
            <img src={Logo4} alt="" className="w-50" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-5.5 p-6.5">
            <input
              placeholder="رقم الهاتف"
              type="tel"
              className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-medium outline-none transition placeholder:text-right focus:border-tealPrimary active:border-tealPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-tealPrimary"
              {...register("phone_number")}
            />
            {errors.phone_number && (
              <FormMessage message={errors.phone_number.message} />
            )}

            <input
              className="w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-medium outline-none transition placeholder:text-right focus:border-tealPrimary active:border-tealPrimary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-tealPrimary"
              type="password"
              placeholder="كلمة المرور"
              {...register("password")}
            />
            {errors.password && (
              <FormMessage message={errors.password.message} />
            )}

            <button
              disabled={isSigningIn}
              className="inline-flex w-full items-center justify-center rounded-md bg-tealPrimary px-4 py-2 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              type="submit"
            >
              {isSigningIn ? <SmallLoading /> : "تسجيل الدخول"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default LoginForm;
